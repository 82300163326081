import { decodeJwtToken } from "../../services/jwt-service";

//##############################################################################################################
// ARQUIVO DE CONTROLE DE SESSÃO LOCAL DO USUÁRIO
// Detalhes: exporta uma Session com as suas respectivas propriedades
//##############################################################################################################

export const Session = {
      // Propriedades gerais da Session
      UsuarioId: localStorage.getItem('_jwt_token_decoded') != null ? JSON.parse(localStorage.getItem('_jwt_token_decoded')).id : null,
      Usuario: localStorage.getItem('_jwt_token_decoded') != null ? JSON.parse(localStorage.getItem('_jwt_token_decoded')).nome : null,
      Email: localStorage.getItem('_jwt_token_decoded') != null ? JSON.parse(localStorage.getItem('_jwt_token_decoded')).email : null,
      VnoId: localStorage.getItem('_jwt_token_decoded') != null ? JSON.parse(JSON.parse(localStorage.getItem('_jwt_token_decoded')).isp).VnoId : null,
      VnoName: localStorage.getItem('_jwt_token_decoded') != null ? JSON.parse(JSON.parse(localStorage.getItem('_jwt_token_decoded')).isp).VnoName : null,
      IspOperationMode: localStorage.getItem('_jwt_token_decoded') != null ? JSON.parse(JSON.parse(localStorage.getItem('_jwt_token_decoded')).isp).ModoOperacao : null,
      Token2f: localStorage.getItem('_jwt_token_decoded') != null ? JSON.parse(localStorage.getItem('_jwt_token_decoded')).token2f : null,
      IsAuthenticated2f: localStorage.getItem('_jwt_token_decoded') != null ? JSON.parse(localStorage.getItem('_jwt_token_decoded')).isAuthenticated2f : null,
      DataPrimeiroAcesso: localStorage.getItem('_jwt_token_decoded') != null ? JSON.parse(localStorage.getItem('_jwt_token_decoded')).dataPrimeiroAcesso : null,
      PerfilId: localStorage.getItem('_jwt_token_decoded') != null ? JSON.parse(JSON.parse(localStorage.getItem('_jwt_token_decoded')).perfil).id : null,
      PerfilSigla: localStorage.getItem('_jwt_token_decoded') != null ? JSON.parse(JSON.parse(localStorage.getItem('_jwt_token_decoded')).perfil).sigla : null,
      DefinicaoServiceId: localStorage.getItem('_jwt_token_decoded') != null ? JSON.parse(JSON.parse(localStorage.getItem('_jwt_token_decoded')).isp).DefinicaoServiceId : null,

      // Array de funcionalidades
      Functions: localStorage.getItem('_jwt_token_decoded') != null ? JSON.parse(JSON.parse(localStorage.getItem('_jwt_token_decoded')).funcionalidades) : null,
      // Língua a ser utilizada na Session
      Lang: localStorage.getItem('_language') != null ? localStorage.getItem('_language') : "pt",
      // JwtToken da Session
      JwtToken: localStorage.getItem('_jwt_token') != null ? localStorage.getItem('_jwt_token') : null,
      // JwtToken decodificado em JSON
      JwtTokenDecoded: localStorage.getItem('_jwt_token_decoded') != null ? JSON.parse(localStorage.getItem('_jwt_token_decoded')) : null,

      // Cria item _jwt_token no localStorage da Session
      setJwtToken: (jwtToken) => {
            localStorage.setItem('_jwt_token', jwtToken);
            Session.JwtToken = jwtToken;
      },

      // Cria item _jwt_token_decoded no localStorage da Session em formato JSON.stringfy
      // E converte em objeto JSON para a propriedade JwtTokenDecoded
      setJwtTokenDecoded: () => {
            localStorage.setItem('_jwt_token_decoded', JSON.stringify(decodeJwtToken()));
            const decodedToken = JSON.parse(localStorage.getItem('_jwt_token_decoded'));

            // Transforma isp em objeto JSON
            if (decodedToken) {
                  if (decodedToken.isp)
                        decodedToken.isp = JSON.parse(decodedToken.isp);
                  if (decodedToken.perfil)
                        decodedToken.perfil = JSON.parse(decodedToken.perfil);

                  if (decodedToken.funcionalidades)
                        decodedToken.funcionalidades = JSON.parse(decodedToken.funcionalidades);
            }

            // Define a propriedade em objeto JSON
            Session.JwtTokenDecoded = decodedToken;
      },

      // Mapeia os dados do JwtToken para as propriedades da Session
      mapJwtTokenDecodedToProperties: () => {
            Session.UsuarioId = Session.JwtTokenDecoded != null ? Session.JwtTokenDecoded.id : null;
            Session.Usuario = Session.JwtTokenDecoded != null ? Session.JwtTokenDecoded.nome : null;
            Session.Email = Session.JwtTokenDecoded != null ? Session.JwtTokenDecoded.email : null;
            Session.VnoName = Session.JwtTokenDecoded != null ? Session.JwtTokenDecoded.isp.VnoName : null;
            Session.VnoId = Session.JwtTokenDecoded != null ? Session.JwtTokenDecoded.isp.VnoId : null;
            Session.IspOperationMode = Session.JwtTokenDecoded != null ? Session.JwtTokenDecoded.isp.ModoOperacao : null;
            Session.Token2f = Session.JwtTokenDecoded != null ? Session.JwtTokenDecoded.token2f : null;
            Session.IsAuthenticated2f = Session.JwtTokenDecoded != null ? Session.JwtTokenDecoded.isAuthenticated2f : null;
            Session.DataPrimeiroAcesso = Session.JwtTokenDecoded != null ? Session.JwtTokenDecoded.dataPrimeiroAcesso : null;
            Session.PerfilId = Session.JwtTokenDecoded != null ? Session.JwtTokenDecoded.perfil.id : null;
            Session.PerfilSigla = Session.JwtTokenDecoded != null ? Session.JwtTokenDecoded.perfil.sigla : null;
            Session.Functions = Session.JwtTokenDecoded != null ? Session.JwtTokenDecoded.funcionalidades : null;
            Session.DefinicaoServiceId = Session.JwtTokenDecoded != null ? Session.JwtTokenDecoded.isp.definicaoServiceId : null;
      },

      // Retorna se a sessão é válida
      // Quando a sessão expira, o usuário faz logout ou fecha o navegador,
      // A o token é excluido e a sessão fica inválida
      isSessionValid: () => {
            return (Session.JwtToken != null && Session.JwtToken != "")
      },
      
      removeSession: () => {
            localStorage.removeItem('_jwt_token');
            localStorage.removeItem('_jwt_token_decoded');
      }
}